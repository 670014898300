import React, {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteSelectPointAction,
    setSelectPointAction,
    updateSelectListPointAction,
} from "../../../store/selectReducer";


const Point = (props) => {

    const dispatch = useDispatch();
    const [editBlock, setEditBlock] = useState(false);
    const [numPoint, setNumPoint] = useState(props.data.quantity);
    const pointList = useSelector((state) => state.selectPoint.list);
    const maxQuantity = props.data.quantity;
    const usage = props.usage;
    const [opv, setOpv] = useState(false);

    useLayoutEffect(() => {
        setNumPoint(props.data.quantity);
        // eslint-disable-next-line
    }, []);
    // eslint-disable-next-line
    useEffect(() =>
    {
        if (props.edit)
        {
            setOpv(false);
            pointList.forEach((point) => {
                if (point.data.item.guid === props.data.item.guid) {
                    setOpv(true);
                    setEditBlock(true);
                }
            })
        } else {
            setOpv(true);
            pointList.forEach((point) => {
                if (point.data.item.guid === props.data.item.guid) {
                    setEditBlock(true);
                }
            })
        }

        let pointData = props.data;

        pointData.quantity = Number(numPoint);

        let dataUpdate = {
            data: pointData,
            num: Number(numPoint)
        }

        dispatch(updateSelectListPointAction(dataUpdate))
        // eslint-disable-next-line
    }, [props.edit])

    const savePoint = (e) => {
        if (Number(e.target.value) <= Number(maxQuantity)) {
            setNumPoint(e.target.value)
        } else {
            setNumPoint(maxQuantity);
            window.alert('Ошибка! Значение не может быть больше чем: ' + maxQuantity);
        }
    }

    const setEditPoint = (pointData, num) => {
        if (!editBlock) {
            // Добавим в хранилище пунктов на отправку
            dispatch(setSelectPointAction({pointData, num}))
        } else {
            // Удалим их хранилища
            dispatch(deleteSelectPointAction(pointData))
        }

        setEditBlock(!editBlock);
    }

    //console.log('pointList', pointList);


        if (usage) {
            // Если фильтруем часто возвращаемые
            if (props.data.item.usage) {
                if (!props.edit) {
                    return (
                        <div className={editBlock ? "pointStage selectBlock" : "pointStage"}
                             onClick={(e) => setEditPoint(props.data, numPoint)}>
                            <div><input type="checkbox" checked={editBlock}/></div>
                            <div className="name">{props.data.item.name}, {props.data.item.unit}</div>
                            <div className="quantity">{numPoint}</div>
                        </div>
                    )
                } else if(opv) {
                    return (
                        <div className="pointStage editPointBG">
                            <div className="name">{props.data.item.name}, {props.data.item.unit}</div>
                            <div className="quantity">
                                <input type="number" value={numPoint} max={maxQuantity} onChange={(e) => savePoint(e)}/>
                            </div>
                        </div>
                    )
                }
            }
        } else {
            // Отображаем все
            if (!props.edit) {
                return (
                    <div className={editBlock ? "pointStage selectBlock" : "pointStage"}
                         onClick={(e) => setEditPoint(props.data, numPoint)}>
                        <div><input type="checkbox" checked={editBlock}/></div>
                        <div className="name">{props.data.item.name}, {props.data.item.unit}</div>
                        <div className="quantity">{numPoint}</div>
                    </div>
                )
            } else if(opv) {
                return (
                    <div className="pointStage editPointBG">
                        <div className="name">{props.data.item.name}, {props.data.item.unit}</div>
                        <div className="quantity">
                            <input type="number" value={numPoint} max={maxQuantity} onChange={(e) => savePoint(e)}/>
                        </div>
                    </div>
                )
            }
        }

}

export default Point;
