import React, {useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import NotFound from "./components/page/error/NotFound";
import CurrentPage from "./components/page/current/CurrentPage";
import PlanPage from "./components/page/plan/PlanPage";
import ArchivePage from "./components/page/archive/ArchivePage";
import InfoPage from "./components/page/info/InfoPage";
import ExitPage from "./components/page/authorization/ExitPage";
import PrivateRoute from "./components/page/authorization/PrivateRoute";
import CurrentObjectPage from "./components/page/current/CurrentObjectPage";
import CurrentDocumentPage from "./components/page/current/CurrentDocumentPage";
import PlanObjectPage from "./components/page/plan/PlanObjectPage";
import PlanDocumentPage from "./components/page/plan/PlanDocumentPage";
import ArchiveObjectPage from "./components/page/archive/ArchiveObjectPage";
import ArchiveDocumentPage from "./components/page/archive/ArchiveDocumentPage";
import periodicSync from "./asinc/periodicSync";
import StartPage from "./components/page/start/StartPage";
import WarehousesPage from "./components/page/warehouses/WarehousesPage";
import CurrentWarehousesPage from "./components/page/warehouses/CurrentWarehousesPage";

function App() {

    periodicSync();

    useEffect(() => {
        const goFullscreen = async () => {
            try {
                // Переводим страницу в полноэкранный режим
                await document.documentElement.requestFullscreen();

                // Проверяем поддержку Screen Orientation API
                if (window.screen.orientation && window.screen.orientation.lock) {
                    // Устанавливаем ориентацию в portrait
                    await window.screen.orientation.lock('portrait');
                    console.log('Ориентация успешно заблокирована в portrait');
                } else {
                    console.warn('Screen Orientation API не поддерживается в этом браузере');
                }
            } catch (error) {
                console.error('Ошибка при переходе в полноэкранный режим или блокировке ориентации:', error);
            }
        };

        goFullscreen();
    }, []);

    return (<div className="container">
            <Routes>
                <Route element={<PrivateRoute/>}>

                    <Route path="/" element={<StartPage/>}/>

                    <Route path="/warehouses" element={<WarehousesPage/>}/>
                    <Route path="/warehouses/:idWarehouses" element={<CurrentWarehousesPage/>}/>

                    <Route path="/loc" element={<CurrentPage/>}/>
                    <Route path="/loc/:idLocation" element={<CurrentObjectPage/>}/>
                    <Route path="/loc/:idLocation/document/:idDocument" element={<CurrentDocumentPage/>}/>

                    <Route path="/plan" element={<PlanPage/>}/>
                    <Route path="/plan/loc/:idLocation" element={<PlanObjectPage/>}/>
                    <Route path="/plan/loc/:idLocation/document/:idDocument" element={<PlanDocumentPage/>}/>

                    <Route path="/archive" element={<ArchivePage/>}/>
                    <Route path="/archive/loc/:idLocation" element={<ArchiveObjectPage/>}/>
                    <Route path="/archive/loc/:idLocation/document/:idDocument" element={<ArchiveDocumentPage/>}/>

                    <Route path="info" element={<InfoPage/>}/>
                    <Route path="exit" element={<ExitPage/>}/>

                    <Route path="*" element={<NotFound/>}/>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
