const defaultState = {
    empty: {
        active: false,
    }
}
// Добавление локации в store
const SET_FILTER_USAGE = "SET_FILTER"
// Удаление локации из store
const RESET_FILTER_USAGE = "RESET_FILTER"

export const emptyUsagePointReducer = (state = defaultState, action) => {
    switch (action.type) {
        case SET_FILTER_USAGE:
            let newState = !state.empty.active
            return {...state, empty: {
                    active: newState
                }}
        case RESET_FILTER_USAGE:
            return {...state, empty: {
                    active: false
                }}
        default:
            return state
    }
}

export const setFilterUsageAction = (payload) => ({type: SET_FILTER_USAGE, payload})
export const resetFilterUsageAction = (payload) => ({type: RESET_FILTER_USAGE, payload})
