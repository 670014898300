import React, {useEffect, useLayoutEffect, useState} from "react";
import AccordionBlock from "./AccordionBlock";
import AccordionUsageBlock from "./AccordionUsageBlock";
import upIcon from "../../../assets/img/chevron-up.svg";
import downIcon from "../../../assets/img/chevron-down.svg";
import {setEditWarehousesAction} from "../../../store/editWarehousesReducer";
import {useDispatch} from "react-redux";

const BlockContent = (props) => {
    const [dataBlock, setDataBlock] = useState([]);
    const [contentBlock, setContentBlock] = useState([]);
    const [open, setOpen] = useState(false);
    const dispatch = useDispatch();
    let openUsage = props.openUsage;

    const openAkkardeonUsage = () => {
        // Если глобально не открыты карточки они сами за себя
        setOpen(!open)
    }
    useLayoutEffect(() => {
        setDataBlock(props.data);
    }, [props]);

    useEffect(() => {
        dispatch(setEditWarehousesAction(true));
    },[dispatch])

    useEffect(() => {

        let Block = [];

        for (let blockItem in dataBlock) {
            Block.push(blockItem);
        }

        setContentBlock(Block)
    }, [dataBlock]);


    return (
        <>
            {contentBlock && contentBlock.map((block, index) =>
                <AccordionBlock key={index} block={block} data={dataBlock} usage={openUsage}/>
            )
            }
        </>
    )

}

export default BlockContent;
