import config from "../config/config-app";

export const send_log_data = (props) => {
    return new Promise((resolve, reject) => {

        try {
            const getCurrentDateTime = () => {
                const now = new Date();
                const year = now.getFullYear();
                const month = String(now.getMonth() + 1).padStart(2, '0');
                const day = String(now.getDate()).padStart(2, '0');
                const hours = String(now.getHours()).padStart(2, '0');
                const minutes = String(now.getMinutes()).padStart(2, '0');
                const seconds = String(now.getSeconds()).padStart(2, '0');

                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            }

            fetch(config.apiUrl + '/api/log_action.php', {
                method: 'POST',
                mode: 'cors',
                body: JSON.stringify({
                    uid: Number(props.uid),
                    uname: props.uname,
                    action: props.action,
                    date: getCurrentDateTime(),
                    request: props.request,
                }), // Тело запроса в JSON-формате
                headers: {
                    // Добавляем необходимые заголовки
                    'Content-type': 'application/json; charset=UTF-8',
                },
            }).then(response => resolve(response))
        } catch (err) {
            console.log(err.message)
            reject(err.message)
        }

    })
}
